import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => (
  <Layout location={location} title={title}>
    <Seo title="All tags" />

    <main className="container px-4 max-w-2xl flex-grow">
      <h1 className="text-3xl mb-4">All tags</h1>
      <div className="flow-root">
        <ul className="flex flex-wrap -m-1">
          {group.map(tag => (
            <li key={tag.fieldValue}>
              <Link
                to={`/tags/${kebabCase(tag.fieldValue)}/`}
                className="tag--button inline-block border-2 rounded-full px-3 py-1 m-1 text-sm"
                key={tag.fieldValue}
              >
                {`${tag.fieldValue} (${tag.totalCount})`}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </main>
  </Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
